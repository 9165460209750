<template>
  <div>
    <div
      class="tw-px-6 tw-box-border tw-gap-4 tw-flex tw-items-center tw-border-b tw-border-neutral-100"
      style="height: 69px; min-height: 69px"
    >
      <div class="tw-text-gray-800 tw-font-medium tw-flex tw-items-center tw-gap-4">
        <h1 class="tw-text-2xl tw-whitespace-nowrap tw-capitalize">{{ $tc('_pricing', 2) }}</h1>
        <tooltip-select tip="_pricing"></tooltip-select>
      </div>
    </div>

    <div class="tw-py-4 tw-px-6">
      <PricingTable />
    </div>
  </div>
</template>

<script>
import PricingTable from '@/components/pricing/PricingTable.vue';
export default {
  props: [],
  components: {
    PricingTable,
  },
};
</script>
